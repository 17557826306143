import {Segment} from "../../api/task/TaskApi";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next/i18n";
const segmentsData:Segment[] =[{
    index: 1,
    text:  i18n.t('audio1_subtitle'),
    image_url: "",
    audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd812-c007-7c9f-82c6-150f37b38a04_0.wav",
    prompt: {
        ch: "",en:''
    },
    nsfw_result: false,
    origin_video_url: "",
    gen_video_url: "",
    tts_duration: 120,
    video_file_size: 1024,
    video_duration: 300,
    estimated_time: 180,
    advance_payment: 100,
    start_time: "2024-01-01T00:00:00Z",
    tag:  i18n.t('audio1_title'),
},
    {
        index: 2,
        text: i18n.t('audio2_subtitle'),
        image_url: "",
        audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd7fc-6914-79de-88e8-80123536a8c7_0.wav",
        prompt: {
            ch: "",en:''
        },
        nsfw_result: false,
        origin_video_url: "",
        gen_video_url: "",
        tts_duration: 120,
        video_file_size: 1024,
        video_duration: 300,
        estimated_time: 180,
        advance_payment: 100,
        start_time: "2024-01-01T00:00:00Z",
        tag:  i18n.t('audio2_title'),
    },
    {
        index: 3,
        text:  i18n.t('audio3_subtitle'),
        image_url: "",
        audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd7fe-81bc-7243-b295-c3ea96b95eeb_0.wav",
        prompt: {
            ch: "",en:''
        },
        nsfw_result: false,
        origin_video_url: "",
        gen_video_url: "",
        tts_duration: 120,
        video_file_size: 1024,
        video_duration: 300,
        estimated_time: 180,
        advance_payment: 100,
        start_time: "2024-01-01T00:00:00Z",
        tag:  i18n.t('audio3_title'),
    },
    {
        index: 4,
        text: "生活的美妙之处就在于，这种事情可能会发生。在空袭的阴影下我遇见了你，这比和平时代到处闲逛，视生命为理所当然要好得多，让我觉得更充实。",
        image_url: "",
        audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd812-e086-7c04-88d8-ab755fb9eb3a_0.wav",
        prompt: {
            ch: "",en:''
        },
        nsfw_result: false,
        origin_video_url: "",
        gen_video_url: "",
        tts_duration: 120,
        video_file_size: 1024,
        video_duration: 300,
        estimated_time: 180,
        advance_payment: 100,
        start_time: "2024-01-01T00:00:00Z",
        tag: "引用-魂断蓝桥",
    },
    {
        index: 5,
        text: "工作不能代表你，银行存款也不能代表你，你开的车也不能代表你，皮夹里的东西不能代表你，衣服也不能代表你，你只是平凡众生中的一个。",
        image_url: "",
        audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd80d-4e67-757e-a6fa-bf491ded2a4f_0.wav",
        prompt: {
            ch: "",en:''
        },
        nsfw_result: false,
        origin_video_url: "",
        gen_video_url: "",
        tts_duration: 120,
        video_file_size: 1024,
        video_duration: 300,
        estimated_time: 180,
        advance_payment: 100,
        start_time: "2024-01-01T00:00:00Z",
        tag: "引用-搏击俱乐部",
    },
    {
        index: 6,
        text: "你等着一辆火车，它会把你带到远方。你明白自己希望火车把你带到哪儿，不过你也心存犹豫。但这一切都没有关系——因为我们在一起。",
        image_url: "",
        audio_url: "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/projects/018fd80d-7e80-7389-8782-392efe3296ef_0.wav",
        prompt: {
            ch: "",en:''
        },
        nsfw_result: false,
        origin_video_url: "",
        gen_video_url: "",
        tts_duration: 120,
        video_file_size: 1024,
        video_duration: 300,
        estimated_time: 180,
        advance_payment: 100,
        start_time: "2024-01-01T00:00:00Z",
        tag: "引用-星际穿越",
    }
]

export default segmentsData;