    import ja from "./ja.json";
    import en from "./en.json";
    import zh from "./zh.json";
    import fr from "./fr.json";
    import pt from "./pt.json"

    export const resources = {
        "ja": {
            translation: ja
        },
        "en": {
            translation: en
        },
        "zh": {
            translation: zh
        },
        "fr": {
            translation: fr
        },
        "pt": {
            translation: pt
        }
    }