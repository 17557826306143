import React, {useEffect, useRef} from "react";
import {StyleSelectorBox} from "../../components/create/StyleSelecorBox";
import {ModelSelector} from "../../components/create/ModelSelector";
import {TagSelector} from "../../components/create/TagSelector";
import {SizeSelector} from "../../components/create/SizeSelector";
import {NumberSelector} from "../../components/create/NumberSelector";
import {CreateButton} from "../../components/create/CreateButton";
import {useCreateStores} from "../../stores/CreateStores";
import {PromptInput} from "../../components/create/PromptInput";
import {useLocation, useNavigate} from "react-router-dom";
import {ErrorDialog} from "../../components/create/ErrorDoialog";
import {PromptHistory} from "../../components/create/PromptHistory";
import {LoadingDialog} from "../../components/create/LoadingDoialog";
import {useUserStores} from "../../stores/UserStores";
import {logEvent} from "firebase/analytics";
import {analytics, SOURCE_ID_KEY} from "../../App";
import {EVENT_PAGE_CREATE} from "../../util/Event";
import {Footer} from "../../components/common/Footer";
import {AudioParamsForm} from "./AudioParamsForm";
import Waveform from "./WaveForm";
import {CreateAudioButton} from "../../components/create/CreateAudioButton";
import {userTaskStores} from "../../stores/TaskStores";
import {Spinner} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

// import pianoClip from '../../assets/piano.mp3';

export function CreateAudio() {
    const {t} = useTranslation();
    const {
        resetStatus,
        showErrDialog,
        errorMsg,
        dismiss,
        queryMaterial,
        tabs,
        showUploadingDialog,
        fetchFaces,
        fetchFacesFromLocal,
        uploadingFaceModel,
        cancelFaceModel,
        uploadingDialogTitle,
        recoverCreateEntity,
        initOnboarding,
        showFaceMode,
        createEntity,
        creatingTask,
        gotoTask
    } = useCreateStores((state) => ({
        resetStatus: state.resetStatus,
        showErrDialog: state.showErrDialog,
        errorMsg: state.errMessage,
        dismiss: state.dismissErrDialog,
        queryMaterial: state.queryMaterial,
        tabs: state.tabs,
        showUploadingDialog: state.showUploadingDialog,
        fetchFaces: state.fetchFaces,
        fetchFacesFromLocal: state.fetchFacesFromLocal,
        uploadingFaceModel: state.uploadingFaceModel,
        cancelFaceModel: state.cancelFaceModel,
        uploadingDialogTitle: state.uploadingDialogTitle,
        recoverCreateEntity: state.recoverCreateEntity,
        initOnboarding: state.initOnboarding,
        showFaceMode: state.showFaceMode,
        createEntity: state.createEntity,
        gotoTask: state.gotoTask,
        creatingTask: state.creatingTask,
    }))

    const {
        taskEntity,
        initTask,
        fetchTaskInfo,
        resetTaskStatus
    } = userTaskStores((state) => ({
        taskEntity: state.taskEntity,
        initTask: state.initTask,
        fetchTaskInfo: state.fetchTaskInfo,
        resetTaskStatus: state.resetStatus,
    }))


    useEffect(() => {
        if (gotoTask) {
            fetchTaskInfo(createEntity.project_id)
        }
    }, [gotoTask])


    const showProcess = () => {
        if (creatingTask || (taskEntity.status === 'AudioGenerating' || taskEntity.status === 'GENERATING'))
            return true
        else {
            return false
        }
    }

    const showGenerating = () => {
        if (taskEntity.status === 'GENERATING') {
            return true
        } else {
            return false
        }
    }

    const {fetchUserInfo} = useUserStores((state) => ({
        fetchUserInfo: state.fetchUserInfo
    }))

    const navigate = useNavigate();

    const handleUnmount = () => {
        resetStatus()
        resetTaskStatus()
        console.log('handleUnmount Create page')
    }

    useEffect(() => {
        console.log('init Create page')
        fetchUserInfo()
        const sourceId = localStorage.getItem(SOURCE_ID_KEY)
        logEvent(analytics, EVENT_PAGE_CREATE, {
            page_path: EVENT_PAGE_CREATE,
            source_id: sourceId
        });
        return handleUnmount
    }, []);

    const showTabs = () => {
        if (tabs && tabs.length > 0) {
            return true
        }
        return false
    }
    const cancelQuery = () => {
        cancelFaceModel()
    }

    const showStatus = () => {
        if (!showGenerating()) {
            return 'Click to Create'
        }
    }
    const audioUrl = () => {
        if (taskEntity.status === 'GENERATED' && taskEntity.projectEntity?.project_segments[0].audio_url) {
            return taskEntity.projectEntity?.project_segments[0].audio_url
        } else {
            return ''
        }
    }

    return (
        <div className='h-screen overflow-y-auto bg-white dark:bg-dark-bg no-scrollbar'>
            <div
                className=" pl-4 pr-4 lg:pl-[22%] lg:pr-[22%] w-screen h-screen bg-white dark:bg-dark-bg pt-[5px] lg:pt-[20px] flex flex-col">
                <ErrorDialog msg={errorMsg} show={showErrDialog}
                             onConfirm={() => {
                                 dismiss()
                                 navigate('/subscribe')
                             }} onCancel={() => {
                    dismiss()
                }}></ErrorDialog>
                <LoadingDialog show={showUploadingDialog} title={uploadingDialogTitle} msg={''} onCancel={() => {
                    cancelQuery()
                }}></LoadingDialog>

                <PromptInput></PromptInput>
                {/*<PromptHistory></PromptHistory>*/}

                <AudioParamsForm></AudioParamsForm>
                <div className='flex flex-col relative  mt-[0px] lg:mt-[70px] h-[250px] w-full flex justify-center items-center'>
                    <div className="relative w-full h-full">
                        {taskEntity.status !== 'GENERATED' &&
                            <img className='w-full h-full shrink-0 bg-no-repeat  object-contain absolute'
                                 src={'https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/chat_tts_intro2.png'}
                            />}
                        {taskEntity.status === 'GENERATED' &&
                            <div className='flex w-full h-full justify-center items-center absolute'>
                                <div className='w-full h-full '>
                                    <Waveform autoPlay={true} audio={audioUrl()}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div
                        className="flex w-full h-[40px] b-0  black dark:white flex-row justify-center items-center text-[18px] font-bold">
                        {showProcess() && <Spinner className="h-6 w-6 text-gray-900/50 mr-[10px]" color="purple"/>}
                        {showProcess() &&
                            <div>
                                {showGenerating() ? 'Generating' : 'Creating'}
                            </div>
                        }
                    </div>
                </div>

                {/* 上传图片作为模型*/}
                <CreateAudioButton></CreateAudioButton>
            </div>
        </div>
    )
}
