import {AudioHomeTryButton} from "./AudioHomeTryButton";
import {AudioHomeComment} from "./AudioHomeComment";
import React from "react";
import { useTranslation } from "react-i18next";

export function AudioIntro() {
    const {t} = useTranslation()
    return <div
        className='flex  h-full w-full flex-col lg:w-[50%] justify-start items-start pr-[5%]'>
        <div
            className='flex flex-col gap-[15px] justify-center items-start self-stretch'>
            <div
                className='flex flex-col gap-[12px] items-start self-stretch  relative'>
                <span
                    className="mt-[0px] lg:mt-[20px] text-[24px] font-bold   text-[#000] relative text-left justify-start ">
               {t("audio_home_info_title")}
                </span>
                <a href="https://www.producthunt.com/posts/chattts-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chattts&#0045;2"
                   target="_blank"><img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=460866&theme=light"
                    alt="ChatTTS - A&#0032;tool&#0032;that&#0032;generates&#0032;natural&#0032;speech&#0046; | Product Hunt"
                    className="w-[250px] h-[54px] lg:mt-[10px]" width="250" height="54"/></a>
            </div>
        </div>
        <AudioHomeComment></AudioHomeComment>
    </div>;
}
