import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';
import {FaPlayCircle, FaPauseCircle} from 'react-icons/fa';
import {Spinner} from "@material-tailwind/react";

interface WaveformProps {
    audio: string; // 假设 audio 是一个 HTMLAudioElement 或者是一个音频文件的 URL 字符串
    autoPlay: boolean;
}

const Waveform = (iPros: WaveformProps) => {
    const {audio,autoPlay} = iPros;
    const containerRef = useRef(document.createElement('div'))
    const waveSurferRef = useRef<WaveSurfer | null>();
    const [isPlaying, toggleIsPlaying] = useState(false);
    const [isLoaded, toggleLoaded] = useState(false);

    useEffect(() => {
        if (typeof containerRef.current === 'string') return;
        const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            barWidth: 4,
            barHeight: 1,
            cursorWidth: 3,
            barRadius: 4,
            fillParent: true,
            progressColor: 'rgb(145, 112, 240)',
            autoplay: autoPlay,
            interact: true,
            dragToSeek: true

        });
        try {
            setTimeout(() => {
                waveSurfer.load(audio);
            }, 2000);
        } catch (e) {
            console.error(e)
        }
        waveSurfer.on('ready', () => {
            toggleLoaded(true)
            if(autoPlay) {
                waveSurfer.play()
                toggleIsPlaying(true);
            }
            waveSurferRef.current = waveSurfer;
        });
        waveSurfer.on('finish', () => {
            toggleIsPlaying(false);
           console.log('finish')
        });

        return () => {
            waveSurfer.destroy();
        };
    }, [audio]);


    const showAction = () => {
        console.log('showAction')
        if (!isLoaded) {
            return (<div></div>)
        }
        if (isPlaying) {
            return <FaPauseCircle size="3em"/>
        } else {
            return <FaPlayCircle size="3em"/>
        }
    }

    const showLoading = () => {
        if (!isLoaded) {
            return (<div className='flex flex-row w-full h-full justify-center items-center'>
                <Spinner className="h-6 w-6 text-gray-900/50 mr-[10px]" color="purple"/>
                <div className='font-bold text-gray-selected text-[14px]'>Audio Loading</div>
            </div>)
        }
    }

    return (
        <WaveSurferWrap>
            <button
                onClick={() => {
                    if (!waveSurferRef.current) return;
                    waveSurferRef.current.playPause();
                    console.log(`isPlaying: ${waveSurferRef.current.isPlaying()}`)
                    toggleIsPlaying(waveSurferRef.current.isPlaying());
                }}
                type="button"
            >
                {showAction()}
                {/*{isPlaying ? <FaPauseCircle size="3em" /> : <FaPlayCircle size="3em" />}*/}
            </button>
            {showLoading()}
            <div ref={containerRef} className='ml-[20px]'/>
            {/*{!isLoaded &&*/}
            {/*    <div className='relative w-full h-[200px]'>*/}
            {/*        <img className='w-full h-full shrink-0 bg-no-repeat  object-contain absolute'*/}
            {/*         src={'https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/chat_tts_intro2.png'}*/}
            {/*    />*/}
            {/*    </div>*/}
            {/*}*/}
        </WaveSurferWrap>
    );
};

Waveform.propTypes = {
    audio: PropTypes.string.isRequired,
};

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  button {
    width: 40px;
    height: 200px;
    border: none;
    padding: 0;
    background-color: white;
  }
`;


export default Waveform;