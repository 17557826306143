import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
export function AudioHomeTryButton() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return <button
        onClick={() => {
            navigate(`/create`)
        }}
        className='flex w-full flex-col gap-[12px] items-start shrink-0 flex-nowrap border-none relative pointer'>
        <div
            className='flex w-full bg-gradient-to-r from-blue-500 to-pink-500  h-[58px] pt-0 pr-[24px] pb-0 pl-[24px] gap-[12px] justify-center items-center shrink-0 flex-nowrap   rounded-[27px]     relative overflow-hidden z-[24]'>
                                      <span
                                          className="flex w-[108px] h-[32px] justify-center items-center  basis-auto  text-[20px] font-bold  text-[#fff] relative text-center whitespace-nowrap ">
                                        {t("audio_home_btn_try")}
                                      </span>
        </div>
    </button>;
}