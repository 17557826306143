import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Spinner} from "@material-tailwind/react";
import React from "react";

interface IProps {
    onConfirm: () => void,
    onCancel: () => void,
    show: boolean,
    msg:string
}

export function ErrorDialog(props: IProps) {

    const {onConfirm, onCancel, show ,msg} = props

    return (<Dialog
        placeholder={''}
        open={show} handler={(e) => {
        onCancel()
    }} className="bg-dark-bg">
        <DialogHeader
            placeholder={''}
            className="text-white">Error!</DialogHeader>
        <DialogBody
            placeholder={''}
            className="text-white">
            {msg}
        </DialogBody>
        <DialogFooter
            placeholder={''}
        >
            <Button
                placeholder={''}
                variant="text"
                color="red"
                onClick={(e) => {
                    e.stopPropagation()
                    onCancel()
                }
                }
                className="mr-1 text-white"
            >
                <span>Cancel</span>
            </Button>
            <Button
                placeholder={''}
                variant="gradient" color="green" onClick={(e) => {
                e.stopPropagation()
                onConfirm()
            }} className="flex flex-row justify-center items-center text-white">
                <span>{'Go Subscribe'}</span>
            </Button>
        </DialogFooter>
    </Dialog>)

}