import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import {FaceEditProjectItem} from "../../components/face-edit/FaceEditProjectItem";
import React from "react";
import {Segment} from "../../api/task/TaskApi";
import segmentsData from "./AudioExampleData";
import Waveform from "../create/WaveForm";
import {useTranslation} from "react-i18next";

export function AudioExampleList() {
    const {t} = useTranslation()
    const audios = segmentsData as Segment[];


    return (
        <div
            className='pt-[20px] lg:pt-[40px] overflow-hidden overflow-y-auto w-[90%] lg:w-[70%] flex flex-col items-start justify-start'>
         <span
             className="text-[32px] font-bold   text-[#0150e0] relative text-left   ">
                     {t('audio_home_audio_title')}
                </span>

            <div className='pt-[10px]'>
                <Grid container spacing={{xs: 0, md: 0}} columns={15} direction='row'>
                    {audios &&
                        audios.map((item, index) => {
                            return (
                                <Grid xs={15} sm={15} md={5} key={index}>
                                    <div className='p-2'>
                                        <Card sx={{
                                            minWidth: 240,
                                            borderRadius: 2,
                                        }}>
                                            <CardContent>
                                                <div className='flex flex-col justify-start items-start'>
                                                    <div
                                                        className='font-bold'>{index < 3 ? t(`audio${index + 1}_title`) : item.tag}</div>
                                                    <div
                                                        className='font-normal text-left text-gray'>{index < 3 ? t(`audio${index + 1}_subtitle`) : item.text}</div>
                                                    <div className='w-full h-full'>
                                                        <Waveform autoPlay={false} audio={item.audio_url}/>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        </div>)
}

