import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import {Input} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCube,
    faFaceSmile,
    faLayerGroup,
    faMattressPillow,
    faSeedling
} from "@fortawesome/free-solid-svg-icons";
import {PrettoSlider} from "./PrettoSlider";
import {AudioParamsFormItem} from "./AudioParamsFormItem";
import {useTranslation} from "react-i18next";

export function AudioParamsForm() {
    const {t} = useTranslation()

    return (
        <div className='flex flex-col mt-[5px] lg:mt-[25px]'>
            <div className='flex flex-row justify-start font-bold text-[18px] mb-[5px]'>{t('create_voice_params_title')}</div>
        <div className='flex flex-col lg:flex-row '>
            <AudioParamsFormItem title={t('create_voice_params_temperate')} icon={ <FontAwesomeIcon className="" size={'lg'} color='black' icon={faFaceSmile}/>}></AudioParamsFormItem>
            <AudioParamsFormItem title={t('create_voice_params_p')} icon={ <FontAwesomeIcon className="" size={'lg'} color='black' icon={faCube}/>}></AudioParamsFormItem>
            <AudioParamsFormItem title={t('create_voice_params_k')} icon={ <FontAwesomeIcon className="" size={'lg'} color='black' icon={faLayerGroup}/>}></AudioParamsFormItem>
        </div>
            {/*<div className='flex flex-row mt-[25px]'>*/}
            {/*    <AudioParamsFormItem title='Audio seed' icon={ <FontAwesomeIcon className="" size={'lg'} color='black' icon={faSeedling}/>}></AudioParamsFormItem>*/}
            {/*    <AudioParamsFormItem title='Text seed' icon={ <FontAwesomeIcon className="" size={'lg'} color='black' icon={faSeedling}/>}></AudioParamsFormItem>*/}
            {/*</div>*/}
        </div>
    )
}