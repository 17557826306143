import React, {useEffect, useState} from 'react';
import {Link, useMatch, useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {useUserStores} from "../stores/UserStores";
import {Avatar, Button} from "@material-tailwind/react";
import {ProfileMenu} from "./nav/ProfileMenu";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer} from "@mui/material";
import {InboxIcon} from "@heroicons/react/20/solid";
import {Drawer} from "./nav/Drawer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import icon from '../assets/icon_logo.png'
import { useTranslation } from "react-i18next";
import {LanguagesMenu} from "./nav/LanguagesMenu";

type Anchor = 'top' | 'left' | 'bottom' | 'right';
const Navbar = () => {
    const {t} = useTranslation();
    const {userInfo, initUserInfo} = useUserStores((state) => ({
        userInfo: state.userEntity,
        initUserInfo: state.initUserInfo
    }))
    useEffect(() => {
        if (!userInfo) {
            initUserInfo()
        }

    }, [userInfo])
    const navigate = useNavigate()


    function useRouteMatch(patterns: string) {
        const result = !!useMatch({path: patterns});
        return result
    }


    const [open, setOpen] = useState(false)

    return (
        <div className="bg-white dark:bg-dark-bg w-full min-h-[60px] h-[60px] flex flex-row select-none">
            <nav className=" flex flex-row justify-between  w-full ">
                <div className="flex flex-row items-center justify-center text-black dark:text-white ml-[5%] lg:ml-10">
                    <img src={icon} className='w-[30px] h-[30px]'></img>
                    <NavLink className="text-[24px] font-bold ml-[10px] " to="/home">ChatTTS</NavLink>
                </div>

                <div className='block lg:hidden '>
                    <React.Fragment key={'right'} >
                        <div
                            className=' w-[100px] h-full flex justify-center items-center'
                            onClick={() => {
                            setOpen(true)
                        }
                        }>
                            <FontAwesomeIcon size={"2xl"} className="bg-transparent  dark:bg-red-500" color='gray' icon={faBars}></FontAwesomeIcon>
                        </div  >
                        <SwipeableDrawer
                            className="bg-transparent dark:bg-red-500"
                            anchor={'right'}
                            open={open}
                            onClose={() => {
                                setOpen(false)
                            }
                            }
                            onOpen={() => {
                                setOpen(true)
                            }
                            }
                        >
                            <Drawer onClose={()=>{setOpen(false)}}></Drawer>
                        </SwipeableDrawer>
                    </React.Fragment>
                </div>

                <div className='flex hidden lg:block h-full items-center justify-center'>
                    <div className="flex flex-row mr-20 justify-center items-center  h-[60px]">
                        <NavLink
                            className={`flex flex-col justify-center ml-10 text-[18px] font-bold  text-primary-color ${useRouteMatch('/create') ? 'text-primary-color' : 'text-black dark:text-white'}`}
                            to="create">{t('nav_create')}</NavLink>

                        {/*<NavLink*/}
                        {/*    className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/my-creations') ? 'text-primary-color' : 'text-white'}`}*/}
                        {/*    to="my-creations">My Creation</NavLink>*/}
                        {/*<NavLink*/}
                        {/*    className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/subscribe') ? 'text-primary-color' : 'text-white'}`}*/}
                        {/*    to="subscribe">Pricing</NavLink>*/}
                        {/*<NavLink*/}
                        {/*    className={`flex flex-col justify-center ml-10 text-[18px] text-primary-color font-bold  ${useRouteMatch('/face-edit') ? 'text-primary-color' : 'text-white'}`}*/}
                        {/*    to="face-edit">FaceEdit</NavLink>*/}

                        {!userInfo && <div className='flex flex-row'>
                            <Button
                                onClick={() => {
                                    navigate('login?mode=register')
                                }}
                                className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gradient-to-r from-blue-500 to-pink-500  min-w-[90px]  pl-[10px] pr-[10px] h-[40px] ml-[20px]"
                                placeholder={''}
                            >{t('nav_sign')}</Button>

                            <Button
                                onClick={() => {
                                    navigate('login?mode=login')
                                }}
                                className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gray  min-w-[90px] pl-[10px] pr-[10px] h-[40px] ml-[20px]"
                                placeholder={''}
                            >{t('nav_login')}</Button>
                        </div>}
                        <LanguagesMenu></LanguagesMenu>
                        {userInfo && <ProfileMenu></ProfileMenu>}
                    </div>
                </div>
            </nav>

        </div>
    );
}

export default Navbar;