import React from "react";
import { useTranslation } from "react-i18next";

export  function AudioHomeComment() {
    const { t } = useTranslation();
    return <div
        className='w-full flex flex-col gap-[16px] mt-[10px] lg:mt-[40px] items-start self-stretch shrink-0  relative'>
                              <span
                                  className="flex justify-start items-start self-stretch font-['Inter'] text-[18px] font-normal  text-black font-bold relative text-left">
                             {t("audio_home_subtitle")}
                              </span>
        <div
            className='hidden lg:visible flex gap-[16px] items-center self-stretch shrink-0 flex-nowrap relative '>
            <img
                className='flex w-[64px] h-[64px] gap-[10px] justify-center items-center shrink-0 flex-nowrap bg-cover bg-no-repeat rounded-[100px] relative  '
                src={'https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/chat_tts_intro2.png'}
            />
            <div className=' flex w-full flex-col items-start  relative  '>
                                  <span
                                      className="h-[24px]   text-[16px] font-medium  text-[#000]   text-left   ">
                                    {t("audio_home_comment_develop_name")}
                                  </span>
                <span
                    className="h-[24px] self-stretch  basis-auto font-['Inter'] text-[16px] font-medium text-[rgba(26,26,26,0.36)] relative text-left  ">
                                       {t("audio_home_comment_develop")}
                                  </span>
            </div>
        </div>
    </div>;
}