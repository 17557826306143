import {PromptTagEntity} from "./PromptTagEntity";
import {StylesEntity} from "./StylesEntity";
import {ModelEntity} from "./ModelEntity";
import {ModelTagEntity} from "./ModelTagEntity";
import {ImageSizeEntity} from "./ImageSizeEntity";
import {FaceModel} from "./FaceModel";

export interface CreateEntity {
    bears: 0
    project_id: string
    prompt: string,
    promptTags: PromptTagEntity[],
    style: StylesEntity,
    model: ModelEntity,
    faceModel: FaceModel,
    // modelTags: [ModelTagEntity]
    // imgSize: ImageSizeEntity,
    batchNumber: number
}

export enum TaskType {
    video = 'video',
    image = 'image',
    audio = 'audio'
}