import React from 'react';
import {AudioBanner} from "./AudioBanner";
import {AudioIntro} from "./AudioIntro";
import {AudioHomeTryButton} from "./AudioHomeTryButton";
import {AudioExampleList} from "./AudioExampleList";


export default function TTSHome() {


    return (
        <div className='flex w-screen flex-col justify-center items-center'>
            <div
                className='flex flex-col justify-center items-center lg:w-[70%] w-[90%] '>
              <span
                  className=" self-stretch text-[32px] font-bold   text-[#0150e0] relative text-left   ">
                     ChatTTS
                </span>
                <div
                    className='flex flex-col lg:flex-row w-full justify-center  items-center   '>
                    <AudioIntro></AudioIntro>

                    <div className='flex flex-col lg:w-[50%] w-full h-full justify-between justify-center items-center flex-1'>
                        <AudioBanner></AudioBanner>

                        <AudioHomeTryButton></AudioHomeTryButton>

                    </div>
                </div>
            </div>
            <AudioExampleList></AudioExampleList>

        </div>
    );
}