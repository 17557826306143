import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import Navbar from "./components/navbar"
import {Create} from "./pages/create/Create";
import {Login} from "./pages/users/Login";
import {
    BrowserRouter,
    Routes,
} from "react-router-dom";
import {TaskDetail} from "./pages/TaskDetail";
import {Subscribe} from "./pages/subscribe/Subscribe";
import {Toaster} from "react-hot-toast";
import {MyCreation} from "./pages/my-creation/MyCreation";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {AccountInfo} from "./pages/accout-info/Account-Info";
import {PaymentResult} from "./pages/payment/PaymentResult";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {Home} from "./pages/home/Home";
import {PrivacyScreen} from "./pages/privacy";
import {TeamsService} from "./pages/teams-service";
import VConsole from 'vconsole';
import {FaceEdit} from "./pages/face-edit/Face-Edit";
import TTSHome from "./pages/home/TTSHome";
import {CreateAudio} from "./pages/create/CreateAudio";
import {Footer} from "./components/common/Footer";
import "./react-i18next/i18n";
import i18n from "./react-i18next/i18n";
const firebaseConfig = {
    apiKey: "AIzaSyCe7gDrsxT8ZqzW2_Z3AyZz2jaIiNKrVCM",
    authDomain: "chat-tts-7378f.firebaseapp.com",
    projectId: "chat-tts-7378f",
    storageBucket: "chat-tts-7378f.appspot.com",
    messagingSenderId: "1052951517324",
    appId: "1:1052951517324:web:4a3c23c06b0f08091843a9",
    measurementId: "G-1MGVCWJMXX"
};
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export  const INVITE_CODE_KEY = 'code'
export  const SOURCE_ID_KEY = 'sourceId'
export  const MODE_KEY = 'mode'

// or init with options
function App() {
    let [searchParams] = useSearchParams();
    const location = useLocation();
    const path = location.pathname;
    let inviteCode = searchParams.get(INVITE_CODE_KEY);
    let sourceId = searchParams.get(SOURCE_ID_KEY);
    inviteCode = 'newbee'
    if(inviteCode) {
        console.log('inviteCode', inviteCode)
        localStorage.setItem(INVITE_CODE_KEY,inviteCode)
    }
    if(sourceId){
        localStorage.setItem(SOURCE_ID_KEY,sourceId)
    }
    const mode = searchParams.get(MODE_KEY);
    if (mode == 'debug') {
        const vConsole = new VConsole({theme: 'dark'});
    }

    useEffect(() => {
        debugger
        const parts = path.split('/').filter(Boolean);
        const languageCode = parts[parts.length - 1] || 'en';
        console.log('primaryLang:  '+languageCode);
        i18n.changeLanguage(languageCode);
        console.log('primaryLang.language:  '+i18n.language);
    }, []);

    return (
        <GoogleOAuthProvider clientId="183052959449-ibbp02i1eq1d61snk2242ltcq2uhk0vk.apps.googleusercontent.com">
            <div className="App h-screen w-screen  flex flex-col">

                <Navbar></Navbar>
                <Routes>
                    <Route path="/home" element={<TTSHome />} />
                    <Route path="/*" element={<TTSHome />} />
                    <Route path="/create" element={<CreateAudio />} />
                    <Route path="login/*" element={<Login />} />
                    {/*<Route path="task/*" element={<TaskDetail />} />*/}
                    {/*<Route path="subscribe/*" element={<Subscribe />} />*/}
                    <Route path="account-info/*" element={<AccountInfo />} />
                    {/*<Route path="my-creations/*" element={<MyCreation />} />*/}
                    {/*<Route path="payment-result/*" element={<PaymentResult />} />*/}
                    <Route path="pp/*" element={<PrivacyScreen />} />
                    <Route path="pp/*" element={<PrivacyScreen />} />
                    <Route path="tos/*" element={<TeamsService />} />
                    {/*<Route path="face-edit/*" element={<FaceEdit />} />*/}
                </Routes>
                <Footer></Footer>
                <Toaster />
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
