import {
    CreateRequest,
    CreateResponse, CreateVideoRequest, GetFacesRequest, GetFacesResponse,
    MaterialRequest,
    MaterialResponse, QueryFacesRequest, QueryFacesResponse,
    UploadRequest,
    UploadResponse, UploadVideoResponse
} from "../api/create/CreateApi";
import {
    TaskDeleteRequest,
    TaskDeleteResponse,
    TaskQueryRequest,
    TaskQueryResponse, TasksQueryRequest,
    TasksQueryResponse
} from "../api/task/TaskApi";
import {
    AuthRequest,
    AuthResponse,
    CreatePaymentRequest,
    CreatePaymentResponse, GetProductRequest, GetProductResponse, GetUserRequest, GetUserResponse,
    LoginRequest,
    LoginResponse
} from "../api/user/UserApi";
import {API_TOKEN, axiosInstance} from "./Api";
import {TaskType} from "../model/create/CreateEntity";

export default class ApiServices<T>{
    private request: any = () => {
        throw new Error('StoryApiService.request is undefined');
    };
    private baseURL: string | ((path: string) => string) = '';

    constructor(options?: {
        baseURL?: string | ((path: string) => string);
        request?<R>(
            params: {
                url: string;
                method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH';
                data?: any;
                params?: any;
                headers?: any;
            },
            options?: T,
        ): Promise<R>;
    }) {
        this.request = options?.request || this.request;
        this.baseURL = options?.baseURL || '';
    }

     genBaseURL(path: string) {
        return typeof this.baseURL === 'string'
            ? this.baseURL + path
            : this.baseURL(path);
    }

    QueryMaterial(req?:MaterialRequest,options?:T):Promise<MaterialResponse>{
        const url = this.genBaseURL('/v1/materials/')
        const method = 'GET';
        return this.request({url,method},options)
    }

    CreateTask(
        req?: CreateRequest,
        options?: T,
    ): Promise<CreateResponse> {
        const url = req?.project_type== TaskType.image?this.genBaseURL('/v1/projects/create'):this.genBaseURL('/v1/projects/audio/create');
        const method = 'POST';
        const data = req;
        return this.request({ url, method, data }, options);
    }

    SubmitVideoTask(
        req?: CreateVideoRequest,
        options?: T,
    ): Promise<CreateResponse> {
        const url = this.genBaseURL('/v1/projects/video/swap');
        const method = 'POST';
        const data = req;
        return this.request({ url, method, data }, options);
    }


    QueryTask(
        req?: TaskQueryRequest,
        options?: T,
    ): Promise<TaskQueryResponse> {
        const url = this.genBaseURL(`/v1/projects/${req?.project_id}`);
        const method = 'GET';
        return this.request({ url, method }, options);
    }

    QueryTasks(
        req?: TasksQueryRequest,
        options?: T,
    ): Promise<TasksQueryResponse> {
        const url = this.genBaseURL(`/v1/projects/fetch`);
        const method = 'GET';
        const params = req;
        return this.request({ url, method ,params}, options);
    }


    Delete(
        req?: TaskDeleteRequest,
        options?: T,
    ): Promise<TaskDeleteResponse> {
        const url = this.genBaseURL(`/v1/projects/delete/${req?.id}`);
        const method = 'DELETE';
        const data = req;
        return this.request({ url, method,data}, options);
    }

    Auth(
        req?:AuthRequest,
        options?: T,
    ):Promise<AuthResponse>{
        const url = this.genBaseURL(`/v1/users/auth`)
        const method = 'POST';
        const data = req;
        return this.request({url,method,data},options)
    }

    GetUserInfo(
        req?:GetUserRequest,
        options?: T,
    ):Promise<GetUserResponse>{
        const url = this.genBaseURL(`/v1/users/me`)
        const method = 'GET';
        return this.request({url,method},options)
    }

    Login(
        req?:LoginRequest,
        options?: T,
    ):Promise<LoginResponse>{
        const url = this.genBaseURL(`/v1/users/me`)
        const method = 'GET';
        return this.request({url,method,req},options)
    }

    CreatePayment(req:CreatePaymentRequest,options?:T):Promise<CreatePaymentResponse>{
        const url = this.genBaseURL(`/v1/subscription/payment`)
        const method = 'POST';
        const data = req;
        return this.request({url,method,data},options)
    }

    GetProducts(req: GetProductRequest, options?: T): Promise<GetProductResponse> {
        const url = this.genBaseURL('/v1/subscription/products')
        const method = 'GET'
        const data = req;
        return this.request({url, method}, options)
    }


    UploadFace(
        req: UploadRequest,
        options?: T,
    ): Promise<UploadResponse> {
        const url = this.genBaseURL('/v1/faces/upload');
        const formData = new FormData();
        formData.append('file', req.file);
       return  axiosInstance.post(url,formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                 'Authorization': 'Bearer ' + API_TOKEN,
            },
           method:"POST"
        })
    }

    UploadVideo(
        req: UploadRequest,
        options?: T,
    ): Promise<UploadVideoResponse> {
        const url = this.genBaseURL('/v1/faces/video');
        const formData = new FormData();
        formData.append('file', req.file);
        return  axiosInstance.post(url,formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + API_TOKEN,
            },
            method:"POST"
        })
    }

    QueryFaces(
        req: QueryFacesRequest,
        options?: T,
    ): Promise<QueryFacesResponse> {
        const url = this.genBaseURL('/v1/faces/query')
        const method = 'GET'
        const params = req;
        return this.request({url, method,params}, options)
    }

    GetFaces(
        req: GetFacesRequest,
        options?: T,
    ): Promise<GetFacesResponse> {
        const url = this.genBaseURL('/v1/faces/fetch')
        const method = 'GET'
        const data = req;
        return this.request({url, method}, options)
    }

}