import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faSquareInstagram,
    faTiktok,
    faFacebookF,
    faTwitter,
    faYoutube,
    faDiscord
} from "@fortawesome/free-brands-svg-icons";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "../../react-i18next/i18n";

export interface IPros{
    youtubeUtr?:string
    insUrl?:string
    ttUrl?:string
    facebookUrl?:string
    twitterUrl?:string
    mailUrl?:string
    discordUrl?:string
}

export function Footer() {
    const {t} = useTranslation()

    const navigator = useNavigate()

    const gotoYoutube = () => {
        window.open('https://youtube.com/')
    }

    const gotoIns = () => {
        window.open('https://youtube.com/')
    }
    const gotoTT = () => {
        window.open('https://www.tiktok.com/')
    }
    const gotoFacebook = () => {
        window.open('https://www.facebook.com/')
    }
    const gotoTwitter = () => {
        window.open('https://twitter.com')
    }
    const gotoMail = () => {
        window.open('mailto:')
    }

    const gotoDiscord = () => {
        window.open('https://discord.com')
    }

    const gotoAffiliate = () => {
        window.open('https://docs.google.com/forms/d/1PIL8dfPHkKX0FV0PzRttcpgIWUt0PYB0pvscTM9t9iE/edit')
    }
    const gotoTeamsOfService = () => {
        navigator('/tos')
    }

    const gotoPrivacy = () => {
        navigator('/pp')
    }
    return (
        <div className=' flex flex-row justify-center '>
        <div
            className='flex w-[80%]  text-black dark:text-gray-d flex-row justify-end items-center bg-white dark:bg-dark-bg w-full h-[60px] pl-[5%] pr-[5%] '>
            <div className=' text-[14px]  flex flex-row  items-center justify-end'>
                {/*<div className=' hover:text-black dark:hover:text-white text-nowrap hidden lg:inline-block'>Chat-tts</div>*/}
                <div className='ml-10  hover:text-black dark:hover:text-white  hidden lg:inline-block'>{t('footer_community')}</div>
                <div className='flex flex-row'>

                    <div
                        onClick={gotoTwitter}
                        className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>
                        <FontAwesomeIcon icon={faTwitter} size={"lg"} color={'white'}/>
                    </div>
                </div>
            </div>
            <div className='flex text-black dark:text-gray-d  text-[14px]  flex-row  justify-center items-center ml-[5px]'>
                <div className='flex flex-row hidden lg:inline-block'>{t('footer_contact')}</div>
                <div
                    onClick={gotoMail}
                    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>
                    <FontAwesomeIcon icon={faEnvelope} size={"lg"} color={'white'}/>
                </div>

                {/*<div*/}
                {/*    onClick={gotoDiscord}*/}
                {/*    className='bg-gray-a hover:cursor-pointer hover:bg-icon p-1 w-[30px] h-[30px] flex justify-center items-center rounded-[15px] ml-2'>*/}
                {/*    <FontAwesomeIcon icon={faDiscord} size={"lg"} color={'white'}/>*/}
                {/*</div>*/}

                <div
                    onClick={gotoAffiliate}
                    className='hidden lg:inline-block flex hover:cursor-pointer  hover:text-black dark:hover:text-white flex-row ml-2'>{t('footer_affiliate')}
                </div>

                <div
                    onClick={gotoTeamsOfService}
                    className='flex hover:cursor-pointer hover:text-black dark:hover:text-whitee flex-row ml-2  text-[12px] lg:text-[14px] text-nowrap'>{t('footer_terms')}
                </div>
                <div
                    onClick={gotoPrivacy}
                    className='flex hover:cursor-pointer hover:text-black dark:hover:text-white flex-row ml-2 text-[12px] lg:text-[14px] text-nowrap'>{t('footer_privacy')}
                </div>

                <a className='hover:cursor-pointer hover:text-black dark:hover:text-white flex-row ml-2   text-[12px] lg:text-[14px] ' href="https://dokeyai.com/" title="Dokey AI Tools Directory">DokeyAI</a>
            </div>
        </div>
        </div>
            )
}