import {PromptTag} from "../PromptTag";
import React, {useRef} from "react";
import {useCreateStores} from "../../stores/CreateStores";
import {PromptTagEntity} from "../../model/create/PromptTagEntity";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const MAX_PROMPT_LENGTH = 500

export function PromptInput() {
    const {t} = useTranslation();
    const {configEntity, createEntity, addPromptTag, clearAllPromptTag, updatePrompt} = useCreateStores((state) => ({
        createEntity: state.createEntity,
        configEntity: state.configEntity,
        addPromptTag: state.addPromptTag,
        clearAllPromptTag: state.clearAllPromptTag,
        updatePrompt: state.updatePrompt
    }))

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const handler = (tag: PromptTagEntity) => {
        addPromptTag(tag)
    }

    const textLength = () => {
        if (textareaRef && textareaRef.current && textareaRef.current.value) {
            return textareaRef.current.value.length
        } else {
            return 0
        }
    }

    return (
        <div>
            <div className="relative ">
                <div className='flex flex-row justify-start font-bold text-[18px] mb-[5px]'>{t('create_prompt_title')}</div>

                <div className="static w-full  rounded-[10px]  border-2 border-primary-color bg-white dark:bg-dark-bg ">
            <textarea
                maxLength={MAX_PROMPT_LENGTH}
                ref={textareaRef}
                onChange={(e) => {
                    updatePrompt(e.target.value)
                    console.log(e.target.value)
                }}
                value={createEntity.prompt}
                placeholder={t('create_prompt_title_hint')}
                name="description" style={{resize: "none", outline: "none"}}
                className="overflow-hidden w-full p-2 pr-[80px] min-h[200px]  rounded-[10px] h-[100px] border-primary-color  text-black dark:text-white">This is the description.</textarea>
                    {/*<div*/}
                    {/*    onClick={clearAllPromptTag}*/}
                    {/*    className="cursor-pointer bg-gray flex justify-center rounded-[15px] items-center absolute right-[10px] top-[10px]  primary-color text-white text-[20px]">*/}
                    {/*    <FontAwesomeIcon size={"lg"} color={'grey'} icon={faCircleXmark}></FontAwesomeIcon>*/}
                    {/*</div>*/}


                    <div
                        className="absolute flex flex-row justify-end right-[10px] bottom-[5px] w-[60px] h-[20px] primary-color text-gray-d ">
                        {textLength()}/500
                    </div>
                </div>
            </div>

            {/*<div className="flex flex-wrap flex-row ml-[10px] mr-[10px] mt-[5px]">*/}
            {/*    {configEntity && configEntity.promptTags.map((item) =>*/}
            {/*        <PromptTag onClick={() => handler(item)} tagEntity={item} showAdd={true}></PromptTag>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    )
}