import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import {Input} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faFaceSmile} from "@fortawesome/free-solid-svg-icons";
import {PrettoSlider} from "./PrettoSlider";

interface IProps {
    title:string
    icon:JSX.Element
}

export function AudioParamsFormItem(iProps:IProps){
    const {title,icon} = iProps

    const [value, setValue] = React.useState(0.5);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    return (<div className='flex flex-col justify-start flex-1 lg:pl-[20px] lg:pr-[20px]'>
            <div id="input-slider" className='font-bold flex flex-row justify-start font-medium'>
                {title}
            </div>
            <div className='flex flex-row justify-center items-center'>
                {icon}
                <div className='pl-[10px] flex-1 flex flex-row justify-center items-center'>
                <PrettoSlider
                    color="secondary"
                    step={0.1}
                    marks min={0} max={1}
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                />
                </div>
                <div className='pl-[15px]'>{value}</div>
            </div>
        </div>
    )
}